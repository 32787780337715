import config from "../App.config";
import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import HeaderComponent from "../Parts/Header";
import FooterComponent from "../Parts/Footer";
import MainComponent from "../Parts/Main";
import RedirectMainComponent from "../Parts/RedirectMain";

const RedirectComponent = () => {
	const {code} = useParams();
	const location = useLocation();
	const [result, setResult] = useState("");

	useEffect(() => {
		if (code) {
			postData(code);
		}
	}, [location]);

	const postData = async (code) => {
		try {
			const response = await fetch(`${config.api_base}/v1/view`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({slug: code}),
			});

			if (response.ok) {
				const data = await response.json();
				setResult(JSON.stringify(data));
			} else {
				setResult("Error occurred");
			}
		} catch (error) {
			console.error("Error occurred:", error);
			setResult("Error occurred");
		}
	};

	return (<>
			{
				result ? (
					<div className="d-flex h-100 text-center text-bg-dark">
						<div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">

							<RedirectMainComponent link_info={result}></RedirectMainComponent>

							<FooterComponent></FooterComponent>
						</div>
					</div>
				) : (<></>)
			}
		</>
	);
};

export default RedirectComponent;
