import React from 'react';

const FooterComponent = () => {
	return (
		<footer className="text-white-50 mt-3">
			<p><a href={"https://rpd.ink"} className={"text-decoration-none"}><img src={"https://rpd.ink/assets/images/icon.png"} height={32} alt={"rpd.ink - shortening and earning"} />
				&nbsp;<span style={{color: "#c724b1"}}>rpd.<span style={{color: "#eaaa00"}}>ink</span></span></a></p>
		</footer>
	);
};

export default FooterComponent;
