import React, {useEffect, useState} from "react";

const RedirectMainComponent = (link_info) => {
	const [counter, setCounter] = useState(5);
	const [redirect, setRedirect] = useState(false);
	const link_data = JSON.parse(link_info.link_info);
	const [url] = useState(link_data.url);

	useEffect(() => {
		const timer = setInterval(() => {
			setCounter((prevCounter) => prevCounter - 1);
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, []);

	useEffect(() => {
		if (counter === 0) {
			if(!redirect) {
				setRedirect(true);
			}
		}
	}, [counter]);

	if (redirect) {
		window.location.href = `https://href.li/?${url}`; // Yeni adrese yönlendirin
	}

	useEffect(() => {
		const Iframe = document.createElement("iframe");
		Iframe.settings = {};
		Iframe.setAttribute("data-aa", "2231981");
		Iframe.src = "//ad.a-ads.com/2231981?size=250x250";
		Iframe.style = "width:250px; height:250px; border:0px; padding:0; overflow:hidden; background-color: transparent;";
		document.getElementById("centerAds").appendChild(Iframe);
	}, []);
	return (
		<>
			<div className="d-flex align-items-center justify-content-center vh-100">
				<div className="col-3">
					<div className="row">
						<div className="col-12" id={"centerAds"}>

						</div>
					</div>
					{counter > 0 ? (
						<small>{counter}sn sonra yönlendirileceksiniz.</small>
					) : (
						<>
							<small>Yönlendiriliyor...</small>
							<p><a href={`https://href.li/?${url}`}>Tıklayın</a></p>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default RedirectMainComponent;
