import React, {useEffect, useState} from "react";
import parse from "html-react-parser";

import config from "../App.config";
import LoadingSvg from "../assets/images/loading.svg";

const MainComponent = (props) => {

	const LoadingImage = `<img src="${LoadingSvg}" alt="loading..." width="64"/>`;
	const [topAds, setTopAds] = useState([]);
	const [leftAds, setLeftAds] = useState([]);
	const [rightAds, setRightAds] = useState([]);
	const [bottomAds, setBottomAds] = useState([]);
	const [centerAds, setCenterAds] = useState([]);

	const placeAds = async (ads) => {
		let tsideAds = ads.filter((item) => item.place === "side");
		let tcenterAds = ads.filter((item) => item.place === "content");
		let tbottomAds = ads.filter((item) => item.place === "bottom");
		let ttopAds = ads.filter((item) => item.place === "top");

		setTopAds(ttopAds[Math.floor(Math.random() * ttopAds.length)]);
		setCenterAds(tcenterAds[Math.floor(Math.random() * tcenterAds.length)]);
		setBottomAds(tbottomAds[Math.floor(Math.random() * tbottomAds.length)]);

		if (tsideAds.length > 1) {
			setLeftAds(tsideAds[0]);
			setRightAds(tsideAds[1]);
		} else if (tsideAds.length === 1) {
			setLeftAds(tsideAds[0]);
			setRightAds(tsideAds[0]);
		}
	};

	const getAds = async () => {
		try {
			const response = await fetch(`${config.api_base}/v1/ads`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response.ok) {
				return await response.json();
			} else {
				console.log("ADS ERROR:: ", response);
			}
		} catch (error) {
			console.error("Error occurred:", error);
			return {error: error};
		}
	};

	useEffect(() => {
		setTimeout(() => {
			getAds().then((newAds) => {
				placeAds(newAds).then(() => {
					console.log("ads placed");
				});

				sessionStorage.setItem("ads", newAds);
			});
		}, 1000);
	}, []);

	useEffect(() => {
		const Iframe = document.createElement("iframe");
		Iframe.settings = {};
		Iframe.setAttribute("data-aa", "2231942");
		Iframe.src = "//ad.a-ads.com/2231942?size=728x90";
		Iframe.style = "width:728px; height:90px; border:0px; padding:0; overflow:hidden; background-color: transparent;";
		document.getElementById("topAds").appendChild(Iframe);

		const IframeLeft = document.createElement("iframe");
		IframeLeft.settings = {};
		IframeLeft.setAttribute("data-aa", "2231944");
		IframeLeft.src = "//ad.a-ads.com/2231944?size=160x600";
		IframeLeft.style = "width:160px; height:600px; border:0px; padding:0; overflow:hidden; background-color: transparent;";
		document.getElementById("leftAds").appendChild(IframeLeft);

		const IframeRight = document.createElement("iframe");
		IframeRight.settings = {};
		IframeRight.setAttribute("data-aa", "2231960");
		IframeRight.src = "//ad.a-ads.com/2231960?size=160x600";
		IframeRight.style = "width:160px; height:600px; border:0px; padding:0; overflow:hidden; background-color: transparent;";
		document.getElementById("rightAds").appendChild(IframeRight);

		const IframeCenter = document.createElement("iframe");
		IframeCenter.settings = {};
		IframeCenter.setAttribute("data-aa", "2231975");
		IframeCenter.src = "//ad.a-ads.com/2231975?size=970x250";
		IframeCenter.style = "width:970px; height:250px; border:0px; padding:0; overflow:hidden; background-color: transparent;";
		document.getElementById("centerAds").appendChild(IframeCenter);

		const IframeBottom = document.createElement("iframe");
		IframeBottom.settings = {};
		IframeBottom.setAttribute("data-aa", "2231976");
		IframeBottom.src = "//ad.a-ads.com/2231976?size=970x90";
		IframeBottom.style = "width:970px; height:90px; border:0px; padding:0; overflow:hidden; background-color: transparent;";
		document.getElementById("bottomAds").appendChild(IframeBottom);

		return () => {
			// Bileşen kaldırıldığında script'i temizle
			/*document.getElementById("topAds").removeChild(Iframe);
			document.getElementById("leftAds").removeChild(IframeLeft);
			document.getElementById("rightAds").removeChild(IframeLeft);
			document.getElementById("centerAds").removeChild(IframeLeft);
			document.getElementById("bottomAds").removeChild(IframeLeft);*/
		};
	}, []);

	return (
		<>
			<div className="row p-0 m-0 mb-2" id="topBar">
				<div className="col-12" id={"topAds"}>
					{/*{parse(topAds?.code ?? LoadingImage)}*/}

				</div>
			</div>
			<div
				className="row p-0 m-0"
				id="mainScreen"
				style={{minHeight: "calc(100vh - 355px)"}}
			>
				<div className="col-2 p-0" id={"leftAds"}>
					{/*{parse(leftAds?.code ?? LoadingImage)}*/}
				</div>
				<div className="col-8 p-0" id={"centerAds"}>
					{/*{parse(centerAds?.code ?? LoadingImage)}*/}
				</div>
				<div className="col-2 p-0" id={"rightAds"}>
					{/*{parse(rightAds?.code ?? LoadingImage)}*/}
				</div>
			</div>
			<div className="row p-0 m-0 mt-2" id={"bottomBar"}>
				<div className="col-12" id={"bottomAds"}>
					{/*{parse(bottomAds?.code ?? LoadingImage)}*/}
				</div>
			</div>
		</>
	);
};

export default MainComponent;
