// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ViewComponent from './Page/View';
import RedirectComponent from "./Page/Redirect";

const App = () => {
	return (
		<Router>
			<Routes>
				<Route path="/:code" element={<ViewComponent />} />
				<Route path="/redirect/:code" element={<RedirectComponent />} />
			</Routes>
		</Router>
	);
};

export default App;
