import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

const HeaderComponent = () => {
	const [timerSeconds, setTimerSeconds] = useState("");
	// eslint-disable-next-line no-script-url
	const [goToLinkHref, setGoToLinkHref] = useState("#");
	const {code} = useParams();

	
	useEffect(() => {
		// goToLink
		let minutes = 0;
		let seconds = 5;
		const url = `/redirect/${code}`;
		let timer = setInterval(myCounter, 1000);

		function myCounter() {
			seconds--;
			if (seconds === 0) {
				minutes--;
				seconds = 60;
			}
			setTimerSeconds(seconds + " Seconds");
			if (minutes === -1) {
				clearTimeout(timer);
				setGoToLinkHref(url);
				document.getElementById("time").classList.add("visually-hidden");
				document.getElementById("goToLink").classList.remove("visually-hidden");
			}
		}

		const links = document.getElementsByTagName("a");
		/* Tıklandığında altta açılacak olan link */
		//const puURL = `/redirect/${code}`;
		const puURL = `https://href.li/?https://rpd.ink`;
		const puTS = Math.round(+new Date() / 1000);

		console.log("T." + localStorage.puTS + "/" + puTS);

		const handleClick = (e) => {
			const puHref = e.currentTarget.getAttribute("href");
			const puTarget = e.currentTarget.getAttribute("target");
			// eslint-disable-next-line no-script-url
			if (puHref !== "#" && puHref !== "javascript:void(0)") {
				e.preventDefault();
				if (puTarget === "_blank") {
					window.open(window.location.href);
				}
				window.open(puHref);
				window.location.href = puURL;
				localStorage.puTS = puTS;
			}
		};

		for (let i = 0, len = links.length; i < len; i++) {
			links[i].addEventListener("click", handleClick);
		}

		return () => {
			for (let i = 0, len = links.length; i < len; i++) {
				links[i].removeEventListener("click", handleClick);
			}
		};

	}, []);

	return (
		<header className="mb-auto">
			<div>
				<div className="float-md-start mb-0">
					<a href={"https://rpd.ink"} className={"text-decoration-none"}><img src={"https://rpd.ink/assets/images/icon.png"} height={32} alt={"rpd.ink - shortening and earning"} />
						&nbsp;<span style={{color: "#c724b1"}}>rpd.<span style={{color: "#eaaa00"}}>ink</span></span></a>
				</div>
				<div className="float-md-end m-0">
					<>
						<a className={"btn btn-sm btn-danger visually-hidden"} href={goToLinkHref} id={"goToLink"}>Skip
							Ads</a>
						{/* eslint-disable-next-line no-script-url */}
						<a className={"btn btn-sm btn-secondary"} href={"#go"}
						   id={"time"}>{timerSeconds}</a>
					</>
				</div>

			</div>
		</header>
	);
};

export default HeaderComponent;
